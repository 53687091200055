function ChangePasswordForm($form) {
    let me = this;
    let mustChangePassword = JSON.parse(localStorage.getItem("mustChangePassword" + Constants.ver));
    function getFields() {
        let data = {}
        $form.find("[name]").each((index, element) => {
            let $element = $(element)
            let elementName = $element.attr('name')
            data[elementName] = $element.val()
        })
        return data
    }

    function success(data) {
        if(mustChangePassword) {
            setStorage("mustChangePassword", false);
            mustChangePassword = false;
            showSecurityError();
            dataActive.previous();
        }
        swal({
            text: trans('Your password was changed successfully'),
            icon: "success",
            dangerMode: true,
        }).then(() => {
            $form[0].reset();
            me.enabelButton();
        })
    }

    this.disableButton = function () {
        $form.find('.change-password-btn').prop('disabled', true);
    }

    this.enabelButton = function () {
        $form.find('.change-password-btn').prop('disabled', false);
    }

    this.ajaxParams = function () {
        $form.find('.error-text').each((index, item) => {
            $(item).empty()
        })
        return {
            url: dataActive.location.marketplace + "/v1/change-password",
            method: "post",
            headers: { 'Authorization': 'Bearer ' + getStorage('loginInformation')["token"] },
            data: getFields(),
            success: success,
            error: function (data, validation) {
                me.enabelButton();
                if (data.status == 422) {
                    $.each(data.responseJSON, function (key, value) {
                        $form.find(`[data-class=${key}]`).text(value)
                    })
                } else if (data.status == 460) {
                    $form.find(`[data-class=current_password]`).text(data.responseJSON.message)
                } else {
                    dataActive.defaultAjaxError(data, validation);
                }
            },
        };
    }

    $form.find('.see-password').on('click', (e) => {
        let icon = e.currentTarget;
        if($(icon).prev().attr('type') == 'password') {
            $(icon).prev().attr('type','text')
            $(icon).removeClass('fa-eye-slash').addClass('fa-eye');
        }
        else {
            $(icon).prev().attr('type','password');
            $(icon).removeClass('fa-eye').addClass('fa-eye-slash');
        }
    });

    const showSecurityError = () => {
        if(mustChangePassword) {
            $form.closest(".theme-account-preferences-item").prev().after(view("partials/error-text-change-password-login"));
        }
        else {
            $form.closest(".container").find('.error-change-password-login').remove();
        }
    }
    showSecurityError();
    setTimeout(() => {
        $("input").removeAttr("readonly")
    }, 300);
}