function UserRegisterForm($form) {
    function getFields() {
        var data = {};
        $form.form.find("[name]").each(function (index, element) {
            var $element = $(element);
            var elementName = $element.attr('name');
            data[elementName] = $element.val();
            if (elementName == 'user_info[birthdate]') {
                var elementValue = $element.val();
                if (elementValue != '' && !elementValue.includes('NaN')) {
                    data[elementName] = DateConvert(elementValue);
                }
            }
        });
        return data;
    }

    function success(data) {
        if (!$.isEmptyObject(data)) {
            setStorage("loginInformation", data)
        }
        dataActive.previous();
    }
    function error(data, validation) {
        dataActive.defaultAjaxError(data, validation);
    }

    this.ajaxParams = function () {
        return {
            url: dataActive.location.marketplace + "/v1/register",
            method: "post",
            data: getFields(),
            success: success,
            error: error,
        };
    }


    function picImage() {
        var userPic = $form.form.find(".user-pic");
        new FilePicker(userPic, { name: userPic.data("name"), target: 'userImage' });
        if (!$.isEmptyObject(userPic[0])) {
            userPic[0].filePicker.load(userPic.data("value"));
        }
    }

    function dataSelect2() {
        $form.form.find(".select2").each(function (index, select) {
            var $select = $(select);
            var placeholder = '<i class="theme-search-area-section-icon lin lin-location-pin"></i>' + $select.data("placeholder");
            $($select).select2({
                ajax: {
                    url: dataActive.location.marketplace + $select.data("url") + '?lang=' + dataActive.location.query.lang.toLowerCase(),
                    dataType: 'json',
                    data: function (params) {
                        return {
                            q: params.term, // search term
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data.data
                        };
                    },
                    cache: false,
                    delay: 1000,
                },
                escapeMarkup: (markup) => markup,

                minimumInputLength: 3,
                language: {
                    inputTooShort: function () {
                        return 'Enter 3 or more characters';
                    }
                },
                allowClear: true,
                placeholder: placeholder,
                templateResult: (item) => item.title ? `<option value="${item.id}">${item.title}</option>` : '',
                templateSelection: (item) => item.title || placeholder,

            });
        });
    }

    ///> init
    dataSelect2();
    var datePickerUserBirthday = $form.form.find('.datePickerUserBirthday');
    new SpecialDate(datePickerUserBirthday, { language: dataActive.location.query.lang, dateType: 'birthdate' });
    dataActive.hooks.register('beforeSubmit', function (configs) {
        configs.form = queueable(configs.form);

        if (configs.form.isBusy == undefined || !configs.form.isBusy()) {
            return configs;
        }
        swal({
            text: trans('some images are uploading'),
            dangerMode: true,
            icon: "error",
        });
    });
    picImage();

    $form.form.find("#city").click(openAutoComplate);

    function openAutoComplate() {
        $form.form.find("#city").parent().append(view("partials/auto-complate-select"));
        document.querySelector(".auto-compate-select input").addEventListener("input", (e) => {
            if(e.target.value.length >= 3) {
                ajax({
                    url: dataActive.location.marketplace + "/v1/search/cities?q=" + e.target.value,
                    success: function(data) {
                        if(data.data == 0) {
                            $form.form.find(".auto-compate-select p").show().text(trans("No results found"))
                        }
                        else {
                            makeCitiesList(data.data);
                            $form.form.find("").hide();
                        }
                    },
                })
            }
            else {
                $form.form.find(".auto-compate-select ul").empty();
            }
        })
    }

    function makeCitiesList(data) {
        $form.form.find(".auto-compate-select ul").empty();
        data.forEach(city => {
            const $row = $(`<li data-id="${city.id}">${city.title}</li>`);
            $form.form.find(".auto-compate-select ul").append($row);
            $row.click (() => {
                $form.form.find("input[name='city").val(city.id);
                $form.form.find("input[name='city").next().text(city.title);
                $form.form.find(".auto-compate-select").remove();
            })
        })
    }

    $('html').on('click', (e) => {
        if((document.querySelector(".auto-compate-select")) && !(document.querySelector(".auto-compate-select")).parentElement.contains(e.target)) {
            $form.form.find(".auto-compate-select").remove();
        }
    })
}